.terre-boxes{

  div{
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 50vw;
    margin-bottom: 60px;
  }
  @media only screen and (max-width: 767px){
    div{
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 100vw;
      margin-bottom: 60px;
    }

  }
}