body{
    background-color: beige;
}
.widecenterer{
    width: 100%;
    text-align: center;
    line-height: 30px;
    min-height: 80vh;
    padding-top: 25vh;

    box-sizing: border-box;
}