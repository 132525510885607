
.modal.fade{
      height: 100vh;
  overflow: hidden;
    .modal-dialog{
      transform: translate(0,-50%) !important;
      margin-top: 50vh;
    }
}

.modal-body{
  font-family: 'Goudy', serif;
  font-weight: normal;
  div{
    padding: .5rem;
    .required{
    padding-top: .5rem;
    padding-bottom: .5rem;


    }
  }
  form{
    div{
      div{
        button{
          background-color: $secondary;
          color: $blue;
          border-radius: 50px;
          border-style: none;
          font-weight: bold;
          padding: .5rem;
          padding-left: 1rem;
          padding-right: 1rem;
        }
      }
    }
  }
}

.modal-content{
  background-color: $blue;
}

.langue_0{
  //&:after{
  //  display:  inline-block;
  //  content: "";
  //  width: 32px;
  //  height: 18px;
  //  background: url('../../public/img/Flag_of_France.svg') no-repeat;
  //  background-size: cover;
  //  margin-right: .5rem;
  //  margin-left: .5rem;
  //}
}

.langue_1{

  //&:after{
  //  display:  inline-block;
  //  content: "";
  //  width: 32px;
  //  height: 18px;
  //  background: url('../../public/img/Flag_of_the_United_Kingdom.svg') no-repeat;
  //  background-size: cover;
  //  margin-right: .5rem;
  //  margin-left: .5rem;
  //}

}