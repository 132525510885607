


/// OVERRIDE VARIABLES

$primary: #000000;
$secondary: #e9b96b;
$blue: #00679d;
// custom css

body{
  margin: 0;
  padding:0;
  color: black  !important;
}

.height-100-VH{
  min-height: 100vh;
}

.btn:focus {
  outline: none;
  box-shadow: none;
}

.bg-blue{
  background-color: $blue;
}


.text-pink{

  color: #f5b4d3;
}
.text-blue{
  color: $blue;
}

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body{
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}


.fs-6 {
  font-size: 0.80rem !important;
}



