.mainlogo {
    width: 30%;
    min-width: 500px;
    @media screen and (max-width: 768px ){
        min-width: 300px;
    }
}

.mainlogo-alt {
    width: 30%;
    min-width: 300px;
}

.home-tiles{
    color: $secondary;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    a:hover{
        color: black !important;
    }
}

