
.mentionsholder {
  width: 100%;
  padding-left: 5%;
  padding-right: 5%;
  box-sizing: border-box;
}

.mentionslegales {
  float: left;
  @media only screen and (max-width: 767px) {
    text-align: center;
  }
}

.language {
  float: right;
}

.chevrons-footer {
  max-width: 35px;
  transform: rotate(180deg);
  margin-bottom: 20px;
}

.footerlogo {
  height: 110px;

  &:hover {

  }

  @media only screen and (max-width: 767px) {
    margin-top: 25px;
  }

}
.size-font-footer{
  font-size: 1.05rem !important;
}

#logo_footer {
  &:hover {
    .st0-1 {
      fill: $blue;
    }
    .st1-1 {
      fill: $blue;
    }
  }
}

.footer {
  div {
    a {
      transition: all 0.5s;
      text-decoration: underline;
      color: $blue;

      &:hover {


      }
    }
  }

}