.bouteille-l{

    background-position: left;
    background-size: contain;
    background-repeat: no-repeat;

    @media only screen and (max-width: 767px){
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        height: 500px;
    }
}

.bouteille-r{
    background-position: right;
    background-size: contain;
    background-repeat: no-repeat;

    @media only screen and (max-width: 767px){
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        height: 500px;

    }
}

.vin-boxes{

    div{
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 50vw;
        margin-bottom: 60px;
    }
    @media only screen and (max-width: 767px){
        div{
            display: flex;
            align-items: center;
            justify-content: center;
            min-height: 100vw;
            margin-bottom: 60px;
        }

    }
}