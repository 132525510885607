
@import '~bootstrap/scss/_functions';
@import '~bootstrap/scss/_variables';
@import '~bootstrap/scss/mixins/_breakpoints';
.navbar-btn{
  position: fixed;
  top: 30px;
  left: 30px;

  &.open {

    &#js-open-menu {

      .top-bar {
        transform: rotate(40deg);
        transform-origin: 10% 10%;
      }

      .middle-bar {
        opacity: 0;
      }

      .bottom-bar {
        transform: rotate(-40deg);
        transform-origin: 10% 10%;
      }

    }
  }
}
.menu {
  z-index: 10;
  background-color: unset;
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100vh;
  max-width: 100vw;
  transition: all .5s;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  transform: translate(-100vw, 0);
  @media only screen and (max-width: 767px){
    width: 100vw;
  }
  a {
    text-decoration: none;
    transition: all .5s;

    width: 100%;
    text-align: center;
    color: $secondary;
    &:hover {
      color: $secondary;
    }

    li {
      background-color: unset;
      transition: all .2s;
      color: $secondary;
      &:hover {
        transition: all .2s;
        color: white;
      }
    }

  }
  .translate-menu {
    text-decoration: none;
    transition: all .5s;
    transform: translate(-100vw, 0);
    width: 100%;
    text-align: center;
    color: black;

    &:hover {
      color: $secondary;
    }

    a {
      background-color: unset;
      transition: all .2s;
      color: black;

      &:hover {
        transition: all .2s;
        color: $secondary;
      }
    }
  }

  &.open {
    max-width: 100vw;
    background-color: rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(16px);
    -webkit-backdrop-filter: blur(16px);
    transform: translate(0%, 0);
    a {
      color: $secondary;
      transition: all .5s;

      width: 100%;
      text-align: center;

      li {

      }
    }
    .translate-menu {
      transition: all .5s;
      transform: translate(0%, 0);
      width: 100%;
      text-align: center;
    }
  }

}

.navbar-btn {
  border: 0;
  z-index: 1000;
  background-color: unset;
}

.navbar-btn:focus,
.navbar-btn:active,
.toggler-icon:focus {
  outline: none;
  box-shadow: none;
  border: 0;
}

.toggler-icon {
  width: 50px;
  height: 3px;
  background-color: $secondary;
  display: block;
  transition: all 0.2s;
}

.middle-bar {
  margin-top: 10px;
  margin-bottom: 10px;
}

.chevrons{
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translate(-50%,0);
  width: 35px;

}




