@font-face {
    font-family: 'GoudyOSF';
    src: url('../font/Goudy-BoldOsF.woff2') format('woff2'),
    url('../font/Goudy-BoldOsF.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('../font/MyriadPro-Regular.woff2') format('woff2'),
    url('../font/MyriadPro-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'BellMT';
    src: url('../font/BellMT.woff2') format('woff2'),
    url('../font/BellMT.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Goudy';
    src: url('../font/Goudy.woff2') format('woff2'),
    url('../font/Goudy.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Goudy';
    src: url('../font/Goudy-Bold.woff2') format('woff2'),
    url('../font/Goudy-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Avenir Book';
    src: url('../font/Avenir-BookOblique.woff2') format('woff2'),
    url('../font/Avenir-BookOblique.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir Book';
    src: url('../font/Avenir-Book.woff2') format('woff2'),
    url('../font/Avenir-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir';
    src: url('../font/Avenir-MediumOblique.woff2') format('woff2'),
    url('../font/Avenir-MediumOblique.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir';
    src: url('../font/Avenir-LightOblique.woff2') format('woff2'),
    url('../font/Avenir-LightOblique.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir';
    src: url('../font/Avenir-Black.woff2') format('woff2'),
    url('../font/Avenir-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir';
    src: url('../font/Avenir-Light.woff2') format('woff2'),
    url('../font/Avenir-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir';
    src: url('../font/Avenir-Heavy.woff2') format('woff2'),
    url('../font/Avenir-Heavy.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir Book';
    src: url('../font/Avenir-BookOblique.woff2') format('woff2'),
    url('../font/Avenir-BookOblique.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir';
    src: url('../font/Avenir-HeavyOblique.woff2') format('woff2'),
    url('../font/Avenir-HeavyOblique.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir';
    src: url('../font/Avenir-Medium.woff2') format('woff2'),
    url('../font/Avenir-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir Black Oblique';
    src: url('../font/Avenir-BlackOblique.woff2') format('woff2'),
    url('../font/Avenir-BlackOblique.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir Book';
    src: url('../font/Avenir-Book.woff2') format('woff2'),
    url('../font/Avenir-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir';
    src: url('../font/Avenir-Roman.woff2') format('woff2'),
    url('../font/Avenir-Roman.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir';
    src: url('../font/Avenir-Oblique.woff2') format('woff2'),
    url('../font/Avenir-Oblique.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

.bellmt{
    font-family: 'BellMT', serif;
    font-weight: normal;
}

.goudy{
    font-family: 'Goudy', serif;
    font-weight: normal;
}

.goudy-bold{
    font-family: 'Goudy';
    font-weight: bold;
    font-style: normal;
}

.goudy-old{

    font-family: 'GoudyOSF', serif;
    font-weight: normal;
}

.myriad-pro{
    font-family: 'Myriad Pro';
    font-weight: normal;
    font-style: normal;
}

//.avenir-book{
//    font-family: 'AvenirBook', sans-serif;
//    font-weight: normal;
//}
//
//.avenir-medium-oblique{
//    font-family: 'Avenir';
//    font-weight: 500;
//    font-style: italic;
//}

.avenir-light-oblique{
    font-family: 'Avenir';
    font-weight: 300;
    font-style: italic;
}

.avenir-black{
    font-family: 'Avenir';
    font-weight: 900;
    font-style: normal;
}
.avenir-light{
    font-family: 'Avenir';
    font-weight: 300;
    font-style: normal;
}

.avenir-heavy{
    font-family: 'Avenir';
    font-weight: 900;
    font-style: normal;
}

.avenir-book-oblique{
    font-family: 'Avenir Book';
    font-weight: normal;
    font-style: italic;
}

.avenir-heavy-oblique{
    font-family: 'Avenir';
    font-weight: 900;
    font-style: italic;
}

.avenir-medium{
    font-family: 'Avenir';
    font-weight: 500;
    font-style: normal;
}

.avenir-black-oblique{
    font-family: 'Avenir Black Oblique';
    font-weight: 900;
    font-style: italic;
}

.avenir-book {
    font-family: 'Avenir Book';
    font-weight: normal;
    font-style: normal;
}

.avenir-roman {
    font-family: 'Avenir';
    font-weight: normal;
    font-style: normal;
}

.avenir-oblique {
    font-family: 'Avenir';
    font-weight: normal;
    font-style: italic;
}