


.topimgholder{
    width: 100%;
    height: 100vh;
    background-position: center;
    background-size: cover;
    position: relative;
}
.topimg{
    width: 100%;
}
.parent {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 90%;
    margin-left: 5%;
}

.cell{
    width: 95%;
    max-width: 600px;
    margin:50px;

}


.actutitle{
    font-size: 30px;
}

.actuimgholder{
    aspect-ratio: 16 / 9;
    overflow: hidden;
    position: relative;
}

.actugridimg{
    width: 100%;
    left: 0;
    top: 50%;
    position: absolute;
    transform: translate(0,-50%);
}




@media (max-width: 1000px) {
    .div0 {
        grid-area: 1 / 1 / 3 / 3;
    }

    .div1 {
        grid-area: 1 / 2 / 2 / 3;
    }

    .div2 {
        grid-area: 2 / 1 / 3 / 2;
    }

    .div3 {
        grid-area: 2 / 2 / 3 / 3;
    }

    .cell{
        width: 95%;
        max-width: 600px;

        margin:20px;

    }

}
