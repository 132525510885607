.topimgholder{
    width: 100%;
    height: 100vh;
    background-position: center;
    background-size: cover;
    position: relative;
}



.pagetitle{
    color: orange;
    font-size: 30px;
    text-align: center;
    width: 100%;
    padding-top: 50px;
    padding-bottom: 25px;
    box-sizing: border-box;
}

.centeredparagraph{
    text-align: center;
    padding-left: 12%;
    padding-right: 12%;
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
    box-sizing: border-box;
    font-size: 25px;
}

.halfboxholder{
    margin-top: 50px;
    margin-bottom: 50px;
    width: 100%;
    float: left;
    height: fit-content;
}

.halfbox_img{
    width: 50%;
    height: 100vh;
    float: left;
    background-position: center;
    background-size: cover;

}

.halfbox_txtcontainer{
    width: 50%;
    height: 100vh;
    float: left;
    text-align: center;
    padding-top: 50vh;
    font-size: 25px;
    box-sizing: border-box;


}

.halfbox_txt{
    width: 100%;
    transform: translate(0,-50%);
}


@media (max-width: 1000px) {

    .halfbox_img {
        width: 100%;
        height: 100vh;
        float: left;
        background-position: center;
        background-size: cover;

    }

    .halfbox_txtcontainer {
        width: 100%;
        height: 100vh;
        float: left;
        text-align: center;
        padding-top: 50vh;
        font-size: 25px;
        box-sizing: border-box;


    }

}