
@import "custom";
@import "font.scss";
@import "accueil.scss";
@import "actualite.scss";
@import "footer.scss";
@import "header.scss";
@import "login.scss";
@import "philosophie.scss";
@import "actualite.scss";
@import "terre.scss";
@import "cognacs";
@import "vins";
@import "modal";


@import "~bootstrap/scss/bootstrap";
@import "form-control";


@include media-breakpoint-up(sm) {
  .patate{
    width: 100%;
  }
}

.logo-svg:hover{
  .st0,.st1{
    fill: white !important;
  }
}

.fs-5-5{
  font-size: 1.1rem !important;
}